import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'

const API_URL2 = `${process.env.REACT_APP_API_URL2}/api/payments`

const getPayments = (query: string): Promise<UsersQueryResponse> => {
  return axios.get(`${API_URL2}?${query}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getPaymentById = (id: ID): Promise<User | undefined> => {
  return (
    axios
      .get(`${API_URL2}/${id}`)
      // .then((response: AxiosResponse<Response<any>>) => response.data)
      .then((response: Response<any>) => response.data)
  )
}

const createPayment = (user: any): Promise<User | undefined> => {
  return axios
    .post(`${API_URL2}`, user, {headers: {'Content-Type': 'multipart/form-data'}})
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updatePayment = (user: any, id: ID): Promise<User | undefined> => {
  return axios
    .post(`${API_URL2}/${id}`, user, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deletePayment = (Id: ID): Promise<void> => {
  return axios.delete(`${API_URL2}/${Id}`).then(() => {})
}

const deleteSelectedPayment = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${API_URL2}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  deleteSelectedPayment,
  getPayments,
  createPayment,
  deletePayment,
  getPaymentById,
  updatePayment,
}
