import clsx from 'clsx'
import React from 'react'
import Select from 'react-select'
import {useThemeMode} from '../../../partials'

type Prop = {
  label: string
  formik: any
  isUserLoading: boolean
  name: string
  placeholder?: string
  isRequired?: boolean
  isDisabled?: boolean
  options?: Array<{value: string; label: string}>
}

const SearshSelect: React.FC<Prop> = ({
  formik,
  name,
  placeholder,
  label,
  isRequired = false,
  isDisabled = false,
  options = [],
}) => {
  const {mode} = useThemeMode()
  const isLight = mode === 'light'

  // Custom styles for react-select based on theme
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: isLight ? 'var(--kt-input-bg)' : 'var(--kt-input-solid-bg)',
      borderColor: isLight ? 'var(--kt-input-border)' : 'var(--kt-input-solid-bg)',
      color: isLight ? 'var(--kt-input-color)' : 'var(--kt-input-solid-color)',
      transition: 'color 0.2s ease',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: isLight ? 'var(--kt-input-color)' : 'var(--kt-input-solid-color)',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: isLight ? 'var(--kt-input-bg)' : 'var(--kt-input-solid-bg)',
      color: isLight ? 'var(--kt-input-color)' : 'var(--kt-input-solid-color)',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? isLight
          ? 'var(--kt-input-active-bg)'
          : 'var(--kt-input-solid-active-bg)'
        : state.isFocused
        ? isLight
          ? 'var(--kt-input-hover-bg)'
          : 'var(--kt-input-solid-hover-bg)'
        : isLight
        ? 'var(--kt-input-bg)'
        : 'var(--kt-input-solid-bg)',
      color: isLight ? 'var(--kt-input-color)' : 'var(--kt-input-solid-color)',
    }),
  }

  return (
    <div className='w-100'>
      <label className={` ${isRequired ? 'required' : ''} fw-bold fs-6 mb-2`}>{label}</label>
      <Select
        options={options}
        name={name}
        value={
          formik.values[name] ? options.find((item) => item.value === formik.values[name]) : null
        }
        isDisabled={isDisabled}
        placeholder={placeholder}
        onChange={(selectedOption) => {
          formik.setFieldValue(name, selectedOption?.value)
        }}
        classNamePrefix={`react-select-${isLight ? 'light' : 'dark'}`}
        className={clsx(
          `react-select-container`,
          {'is-invalid': formik.touched[name] && formik.errors[name]},
          {
            'is-valid': formik.touched[name] && !formik.errors[name],
          }
        )}
        styles={customStyles} // Apply custom styles here
      />
      {formik.touched[name] && formik.errors[name] && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors[name]}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default SearshSelect
