import {useContext, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {getCategories} from '../../../../categories-management/categories-list/core/_requests'
import {getCities} from '../../../../../../../_metronic/helpers/_request'
import {getUsers} from '../../../../users-management/users-list/core/_requests'
import {AppContext} from '../../../../../../../AppContext'

const ListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [category, setCategory] = useState<string | undefined>()
  const [categories, setCategories] = useState<any[]>([])
  const [lastLogin, setLastLogin] = useState<string | undefined>()
  const [user, setUser] = useState<string | undefined>()
  const [users, setUsers] = useState<any[]>([])
  const [city, setCity] = useState<string | undefined>()
  const [cities, setCities] = useState<any[]>([])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    setCategory(undefined)
    setLastLogin(undefined)
    setUser(undefined)
    setCity(undefined)
  }

  const filterData = () => {
    updateState({
      filter: {
        category_id: category,
        last_login: lastLogin,
        user_id: user,
        city_id: city,
      },
      ...initialQueryState,
    })
  }

  const getRolesListe = async () => {
    const {data}: any = await getCategories('?items=all')
    const modifiedData = await data.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))
    setCategories(modifiedData)
  }

  const getCitiesListe = async () => {
    const data: any = await getCities()
    const modifiedData = await data.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))
    setCities(modifiedData)
  }

  const getUsersListe = async () => {
    const {data}: any = await getUsers('?items=all')
    const modifiedData = await data.map((item: any) => ({
      label: item.first_name + ' ' + item.last_name,
      value: item.id,
    }))
    setUsers(modifiedData)
  }

  useEffect(() => {
    getRolesListe()
    getCitiesListe()
    getUsersListe()
  }, [])
  const {hasPermission} = useContext(AppContext)

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Categories:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='category'
              data-hide-search='true'
              onChange={(e) => setCategory(e.target.value)}
              value={category}
            >
              <option value=''></option>
              {categories &&
                categories.map((item: any) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          {hasPermission('view_user_products') && (
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Utilisateurs:</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='user'
                data-hide-search='true'
                onChange={(e) => setUser(e.target.value)}
                value={user}
              >
                <option value=''></option>
                {users &&
                  users.map((item: any) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </select>
            </div>
          )}
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Villes:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='city'
              data-hide-search='true'
              onChange={(e) => setCity(e.target.value)}
              value={city}
            >
              <option value=''></option>
              {cities &&
                cities.map((item: any) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              disabled={isLoading}
              type='button'
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              type='button'
              disabled={isLoading}
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Filtrer
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {ListFilter}
