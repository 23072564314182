import React, {Fragment, useEffect, useState} from 'react'
import {Document, Image, Page, Text, View, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer'
import {toAbsoluteUrl} from '../AssetHelpers'
import {getPaymentById} from '../../../app/modules/apps/users-management/payment-list/core/_requests'
import {ID} from '../crud-helper/models'
import {number} from 'yup'

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  spaceBetween: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#3E3E3E',
  },
  titleContainer: {flexDirection: 'row', marginTop: 24},
  logo: {width: 90},
  reportTitle: {fontSize: 16, textAlign: 'center'},
  addressTitle: {fontSize: 11, fontWeight: 'bold'},
  invoice: {fontWeight: 'bold', fontSize: 20},
  invoiceNumber: {fontSize: 11, fontWeight: 'bold'},
  address: {fontWeight: 400, fontSize: 10},
  theader: {
    marginTop: 20,
    fontSize: 10,
    fontWeight: 'bold',
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    height: 20,
    backgroundColor: '#DEDEDE',
    borderColor: 'whitesmoke',
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  theader2: {flex: 2, borderRightWidth: 0, borderBottomWidth: 1},
  tbody: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    borderColor: 'whitesmoke',
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  total: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1.5,
    borderColor: 'whitesmoke',
    borderBottomWidth: 1,
  },
  tbody2: {flex: 2, borderRightWidth: 1},
})

// Define the type for the invoiceData
type InvoiceData = {
  id: number
  amount: string
  payment_method: string
  payment_date: string
  reference: string
  user_id: number
  created_at: string
  updated_at: string
  user: {
    id: number
    first_name: string
    last_name: string
    phone: string
    phone_2: string | null
    image: string
    status: string
    email: string
    address: string
    email_verified_at: string
    city_id: number
    created_at: string
    updated_at: string
  }
  next_payment: string
}

type ServiceItem = {
  id: number
  desc: string
  price: number
}

type Props = {
  invoiceData: InvoiceData
}

export const Invoice: React.FC<Props> = ({invoiceData}) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.titleContainer}>
        <View style={styles.spaceBetween}>
          <Image style={styles.logo} src={toAbsoluteUrl('/media/logos/light_logo.png')} />
          <Text style={styles.reportTitle}>Piessa</Text>
        </View>
      </View>

      {/* address */}
      <View style={styles.titleContainer}>
        <View style={styles.spaceBetween}>
          <View>
            <Text style={styles.invoice}>Facture</Text>
            <Text style={styles.invoiceNumber}>Numéro de facture: {invoiceData.reference}</Text>
          </View>
          <View>
            <Text style={styles.addressTitle}>7, Ademola Odede,</Text>
            <Text style={styles.addressTitle}>Ikeja,</Text>
            <Text style={styles.addressTitle}>Lagos, Nigeria.</Text>
          </View>
        </View>
      </View>
      {/* user address */}
      <View style={styles.titleContainer}>
        <View style={styles.spaceBetween}>
          <View style={{maxWidth: 200}}>
            <Text style={styles.addressTitle}>Facturé à</Text>
            <Text
              style={styles.address}
            >{`${invoiceData.user.first_name} ${invoiceData.user.last_name}`}</Text>
            <Text style={styles.address}>{`${invoiceData.user.address}`}</Text>
          </View>
          <Text style={styles.addressTitle}>{invoiceData.payment_date}</Text>
        </View>
      </View>
      {/* header table */}
      <View style={{width: '100%', flexDirection: 'row', marginTop: 10}}>
        <View style={[styles.theader, styles.theader2]}>
          <Text>Services</Text>
        </View>
        <View style={styles.theader}>
          <Text>Prix</Text>
        </View>
      </View>
      {/* Services Table */}
      <Fragment>
        <View style={{width: '100%', flexDirection: 'row'}}>
          <View style={[styles.tbody, styles.tbody2]}>
            <Text>Serivce</Text>
          </View>
          <View style={styles.tbody}>
            <Text>{Number(invoiceData.amount).toFixed(2)} </Text>
          </View>
        </View>
      </Fragment>

      {/* Invoice Information */}
      <View style={{width: '100%', flexDirection: 'row'}}>
        <View style={styles.total}>
          <Text>Total</Text>
        </View>
        <View style={styles.tbody}>
          <Text>{invoiceData.amount}</Text>
        </View>
      </View>
    </Page>
  </Document>
)

type MyInvoiceComponentProps = {
  id: ID
}

const MyInvoiceComponent: React.FC<MyInvoiceComponentProps> = ({id}) => {
  const getData = async () => {
    const data = await getPaymentById(id)
    if (data) {
      SetInvoiceData(data.data)
    }
  }

  useEffect(() => {
    getData()
  }, [id])

  const [invoiceData, SetInvoiceData] = useState<InvoiceData | null>(null)

  return (
    <div>
      {invoiceData && (
        <PDFDownloadLink
          document={<Invoice invoiceData={invoiceData} />}
          fileName={`facture_piessa_${invoiceData.reference}.pdf`}
        >
          {({loading}) =>
            loading ? (
              <div className='menu-item px-3'>
                <div className='menu-link px-3'>Svp Attendez</div>
              </div>
            ) : (
              <div className='menu-item px-3'>
                <div className='menu-link px-3'>Voir</div>
              </div>
            )
          }
        </PDFDownloadLink>
      )}
    </div>
  )
}

export default MyInvoiceComponent
