import JoditEditor from 'jodit-react'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useThemeMode} from '../../../partials'

type Props = {
  formik: any
  name: string
  label?: string
}

const CustomJoditEditor: React.FC<Props> = ({formik, name, label}) => {
  const editor = useRef<any>(null)
  const {mode} = useThemeMode()
  const [content, setContent] = useState<any>(formik.values[name])

  // useEffect(() => {
  //   formik.setFieldValue(name, content)
  // }, [content, formik, name])

  // Custom configuration based on theme
  const config = useMemo(
    () => ({
      buttons: 'ul,ol,fullsize,paste,brush',
      toolbarAdaptive: false,
      toolbarSticky: false,
      showCharsCounter: false,
      showWordsCounter: false,
      buttonsXS: {
        uppercase: {
          command: 'toggleUpper',
          tags: ['span', 'strong', 'em', 'i', 'b'],
        },
      },
      colorPicker: {
        defaultColors: ['#023e8a', '#ff0000'],
      },
      // Apply custom styles based on the theme
      theme: mode === 'light' ? 'default' : 'dark',
      style: {
        editor: {
          backgroundColor: mode === 'light' ? 'var(--kt-input-bg)' : 'var(--kt-input-solid-bg)',
          color: mode === 'light' ? 'var(--kt-input-color)' : 'var(--kt-input-solid-color)',
        },
      },
    }),
    [mode]
  )

  return (
    <>
      {label && (
        <label htmlFor={name} className='form-label'>
          {label}
        </label>
      )}
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        onBlur={(newContent) => {
          setContent(newContent)
          formik.setFieldValue(name, newContent)
        }}
      />
    </>
  )
}

export default CustomJoditEditor
