import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ProductsListWrapper} from './products-list/ProductsList'
import {getUserById} from '../users-management/users-list/core/_requests'
import {useEffect, useState} from 'react'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Liste de tous les produits',
    path: '/products/liste/all',
    isSeparator: false,
    isActive: true,
  },
  {
    title: "Produits de l'utilisateur",
    path: '/products/liste/all/user',
    isSeparator: false,
    isActive: true,
  },

  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductsPage = () => {
  const location: any = useLocation()
  const [user, setUser] = useState<any>()
  const getUserData = async () => {
    if (!location.state) {
      return
    }
    const data: any = await getUserById(location?.state)
    if (data) {
      setUser(data.data)
    }
  }

  useEffect(() => {
    getUserData()
  }, [location?.state])
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='all'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste de tous les produits</PageTitle>
              <ProductsListWrapper />
            </>
          }
        />
        <Route
          path='all/user'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>
                Produits de l'utilisateur {user && user?.first_name + ' ' + user?.last_name}
              </PageTitle>
              <ProductsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/products/liste/all' />} />
    </Routes>
  )
}

export default ProductsPage
