export const truncateHTML = (content: any, maxLength: number) => {
  // Create a temporary DOM element to use the browser's parsing capabilities
  const tempElement = document.createElement('div')
  // Set the HTML content
  tempElement.innerHTML = content
  // Get the plain text from the HTML content
  const plainText = tempElement.textContent || tempElement.innerText || ''

  // Truncate the plain text to the desired length
  if (plainText.length > maxLength) {
    return plainText.substring(0, maxLength) + '...'
  }

  return plainText
}
