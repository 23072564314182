import clsx from 'clsx'
import React, {useState} from 'react'
import {useThemeMode} from '../../../partials'

type Prop = {
  label: string
  formik: any
  isUserLoading: boolean
  name: string
  placeholder?: string
  isRequired?: boolean
  isDisabled?: boolean
  options?: Array<{value: string; label: string}>
}

const CheckboxGroup: React.FC<Prop> = ({
  formik,
  name,
  label,
  isRequired = false,
  isDisabled = false,
  options = [],
}) => {
  const [filter, setFilter] = useState('')
  const {mode} = useThemeMode()
  const isLight = mode === 'light'

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(filter.toLowerCase())
  )

  return (
    <div className='p-3'>
      <label className={` ${isRequired ? 'required' : ''} fw-bold fs-6 mb-2`}>{label}</label>
      <input
        type='search'
        className='form-control mb-3'
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder='Recherche...'
      />
      <div className='row'>
        {filteredOptions.length > 0 &&
          filteredOptions.map((option) => (
            <div
              key={option.value}
              className='form-check form-check-inline col-lg-5 col-sm-12 mb-2'
            >
              <input
                type='checkbox'
                id={option.value}
                name={name}
                value={option.value}
                checked={formik.values[name].includes(option.value)}
                onChange={(e) => {
                  const valueArray = [...formik.values[name]]
                  if (e.target.checked) {
                    valueArray.push(option.value)
                  } else {
                    const index = valueArray.indexOf(option.value)
                    if (index >= 0) {
                      valueArray.splice(index, 1)
                    }
                  }
                  formik.setFieldValue(name, valueArray)
                }}
                className={clsx('form-check-input', {
                  'is-invalid': formik.touched[name] && formik.errors[name],
                })}
                disabled={isDisabled}
              />
              <label
                className={clsx('form-check-label w-100', {
                  'form-check-label-light': isLight,
                })}
                htmlFor={option.value}
              >
                {option.label}
              </label>
            </div>
          ))}
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors[name]}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default CheckboxGroup
