import React from 'react'
import {lazy, FC, Suspense, useContext} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import UsersWrapper from '../pages/users/UsersWrapper'
import {AppContext} from '../../AppContext'
import RolesWrapper from '../pages/roles/RolesWrapper'
import CategoriesWrapper from '../pages/categories/CategoriesWrapper'
import BrandModelsWrapper from '../pages/branadModels/BrandModelsWrapper'
import BrandsWrapper from '../pages/branads/BrandsWrapper'
import BlogCategoriesWrapper from '../pages/blogCategories/BlogCategoriesWrapper'
import BlogsWrapper from '../pages/blogs/BlogsWrapper'
import EnginesWrapper from '../pages/engines/EnginesWrapper'
import ProductsWrapper from '../pages/products/ProductsWrapper'

const PrivateRoutes = () => {
  const {hasPermission} = useContext(AppContext)
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='users/liste/*' element={<UsersWrapper />} />
        <Route path='roles/liste/*' element={<RolesWrapper />} />
        <Route path='categories/liste/*' element={<CategoriesWrapper />} />
        <Route path='blog-categories/liste/*' element={<BlogCategoriesWrapper />} />
        <Route path='blogs/liste/*' element={<BlogsWrapper />} />
        <Route path='brands/liste/*' element={<BrandsWrapper />} />
        <Route path='engines/liste/*' element={<EnginesWrapper />} />
        <Route path='products/liste/*' element={<ProductsWrapper />} />
        <Route path='brand-models/liste/*' element={<BrandModelsWrapper />} />
        {/* {hasPermission('view_user') && <Route path='users/liste/*' element={<UsersWrapper />} />} */}

        {/* -------------------------------------------------- */}
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
