import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget10,
  TablesWidget5,
  StatisticsWidget5,
  ListsWidget1,
} from '../../../_metronic/partials/widgets'
import {useContext, useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {getUserByToken} from '../../modules/auth/core/_requests'
import {AppContext} from '../../../AppContext'
import axios, {AxiosResponse} from 'axios'
import {UsersQueryResponse} from './_model'
type Props = {
  hasPermission: any
  statistics: any // Define the type according to your data structure
}
const DashboardPage: React.FC<Props> = ({statistics, hasPermission}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/general/gen017.svg'
          color='dark'
          iconColor='white'
          title='Total des Produits'
          description={statistics?.total_products}
          titleColor='white'
          descriptionColor='gray-400'
        />
      </div>
      <div className='col-xl-4'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/general/gen017.svg'
          color='body-white'
          iconColor='dark'
          title='Total de vos Produits'
          description={statistics?.user_total_products}
          titleColor='gray-900'
          descriptionColor='gray-400'
        />
      </div>

      <div className='col-xl-4'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/communication/com014.svg'
          color='primary'
          iconColor='white'
          title='Total des Utilisateurs'
          description={statistics?.total_users}
          titleColor='white'
          descriptionColor='white'
        />
      </div>
    </div>
    {/* end::Row */}
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget1 className='card-xl-stretch mb-xl-8' />
      </div>

      <div className='col-xl-8'>
        <TablesWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}
    {/* begin::Row */}
    {hasPermission('view_payment') && (
      <div className=' gy-5 g-xl-8'>
        <div>
          <MixedWidget10
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='250px'
          />
        </div>
      </div>
    )}
    {/* end::Row */}
    {/* end::Row */}
  </>
)

const DashboardWrapper = () => {
  const API_URL2 = `${process.env.REACT_APP_API_URL2}/api/statistics`
  const {auth, logout, setCurrentUser} = useAuth()
  const {setPermission, hasPermission} = useContext(AppContext)
  const [statistics, setStatistics] = useState<any>()
  const getStatistics = (): Promise<UsersQueryResponse> => {
    return axios.get(`${API_URL2}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }
  const getData = async () => {
    const data = await getStatistics()
    if (data) {
      setStatistics(data)
    }
  }

  const CheckIsAuth = async () => {
    try {
      const token = localStorage.getItem('token')

      if (!token) {
        const {data} = await getUserByToken(auth?.api_token)

        if (data) {
          await setCurrentUser(data)
          await setPermission(data.roles)
        } else {
          setCurrentUser(undefined)
          logout()
        }
      }
    } catch (error) {
      setCurrentUser(undefined)
      logout()
    }
  }
  useEffect(() => {
    getData()
    CheckIsAuth()
  }, [])
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage statistics={statistics} hasPermission={hasPermission} />
    </>
  )
}

export {DashboardWrapper}
