/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {getBlogs} from '../../../../app/modules/apps/blogs-management/blogs-list/core/_requests'
import {UserInfoCell} from '../../../../app/modules/apps/blogs-management/blogs-list/table/columns/UserInfoCell'

type Props = {
  className: string
}

const ListsWidget1: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<any[]>([])

  const getData = async () => {
    const blogs: any = await getBlogs('')
    if (blogs) {
      setData(blogs.data)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Derniers Posts</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5'>
        {data &&
          data.map((item) => (
            <div key={item.id} className='d-flex align-items-center mb-7'>
              <div className='symbol symbol-50px me-5'>
                <img src={item.image_url} alt={item.title} className='rounded-circle' />
              </div>

              <div className='d-flex flex-column'>
                <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                  {item.title}
                </a>
                <span className='text-muted fw-semibold'>{item.category.name}</span>
              </div>
            </div>
          ))}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget1}
