import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import axios, {AxiosResponse} from 'axios'
import {UsersQueryResponse} from '../../../../app/pages/dashboard/_model'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidget10: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const API_URL2 = `${process.env.REACT_APP_API_URL2}/api/statistics`
  const [data, setData] = useState<any>()
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear())
  const [years, setYears] = useState<number[]>([])

  const getStatistics = (query: string): Promise<UsersQueryResponse> => {
    return axios.get(`${API_URL2}/${query}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  const getData = async (year: number) => {
    const monthData: any = await getStatistics(year.toString())

    if (monthData) {
      setData(monthData)
      if (years.length === 0) {
        const startYear = monthData?.first_payment_year
        const currentYear = monthData?.current_year
        const yearRange = Array.from({length: currentYear - startYear + 1}, (_, i) => startYear + i)
        setYears(yearRange)
      }
    }
  }

  useEffect(() => {
    getData(selectedYear)
  }, [selectedYear])

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, data))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, data])

  return (
    <div className={`card ${className}`}>
      <div className='card-body d-flex flex-column p-0'>
        <div className='flex-grow-1 card-p pb-0'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2'>
              <a href='#' className='text-dark text-hover-primary fw-bold fs-3'>
                Statistiques de paiement
              </a>
              <div className='text-muted fs-7 fw-semibold'>Totaux des paiements mensuels</div>
            </div>
            <div className='d-flex gap-3 align-items-center'>
              <div className={`fw-bold fs-3 text-${chartColor}`}>
                Total : {data?.total_current_year} DH
              </div>
              <div>
                <select
                  className='form-select'
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom'></div>
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, data: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const strokeColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')

  const categories = data?.monthly_payments.map((payment: any) => payment.month) || []
  const seriesData = data?.monthly_payments.map((payment: any) => parseFloat(payment.total)) || []

  return {
    series: [
      {
        name: 'Total',
        data: seriesData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {show: false},
      zoom: {enabled: false},
      sparkline: {enabled: true},
    },
    fill: {type: 'solid', opacity: 1},
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories,
      axisBorder: {show: false},
      axisTicks: {show: false},
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    tooltip: {
      style: {fontSize: '12px'},
      y: {
        formatter: (val) => `${val}DH`,
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export {MixedWidget10}
