import React, {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {initialUser, User} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {ListLoading} from '../components/loading/ListLoading'
import {updateRole, createRole, getPermissions} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import Input from '../../../../../../_metronic/layout/forms/Inputs/Input'
import CheckboxGroup from '../../../../../../_metronic/layout/forms/Inputs/CheckboxGroup'
import {AppContext} from '../../../../../../AppContext'
import {ObjectToArrayFormData} from '../../../../../../_metronic/helpers/function/ObjectToFormData'

type Props = {
  isUserLoading: boolean
  user: User
}

const EditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const [permissions, setPermissions] = useState<any>([])
  const {errorToast, successToast} = useContext(AppContext)
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit, setUserForEdit] = useState<User>({
    ...user,
    permissions: user.permissions ? user.permissions : [],
    name: user.name || initialUser.name,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: Yup.object().shape({
      permissions: Yup.array()
        .of(Yup.string().required('ID requis'))
        .min(1, 'Les permissions sont requises')
        .required('Les permissions sont requises'),
      name: Yup.string()
        .min(3, 'Au moins 3 caractères')
        .max(50, 'Maximum 50 caractères')
        .required('Le nom est requis'),
    }),
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const dataToSend: any = await ObjectToArrayFormData(values)
          await updateRole(dataToSend, values.id)
          await successToast('Role mise à jour avec succès')
          await cancel(true)
        } else {
          const dataToSend: any = await ObjectToArrayFormData(values)
          await createRole(dataToSend)
          await successToast('Role créé avec succès')
          await cancel(true)
        }
      } catch (error: any) {
        errorToast(error.response.data.message)
      } finally {
        setSubmitting(true)
      }
    },
  })

  useEffect(() => {
    if (user.permissions) {
      formik.setFieldValue(
        'permissions',
        user.permissions.map((permission: any) => permission.name)
      )
    }
  }, [user.permissions])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const getPermissionsListe = async () => {
    const data: any = await getPermissions('')
    const modifiedData = data.map((item: any) => ({
      label: item.label,
      value: item.name,
    }))
    setPermissions(modifiedData)
  }

  useEffect(() => {
    getPermissionsListe()
  }, [])

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <Input
              type='string'
              name='name'
              formik={formik}
              label='Nom'
              isUserLoading={isUserLoading}
              isRequired={true}
            />
          </div>

          <div className='fv-row mb-7'>
            <CheckboxGroup
              options={permissions.length > 0 ? permissions : []}
              name='permissions'
              formik={formik}
              label='Permissions'
              isUserLoading={isUserLoading}
              isRequired={true}
            />
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Annuler
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Valider</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <ListLoading />}
    </>
  )
}

export {EditModalForm}
