import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {User} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {ListLoading} from '../components/loading/ListLoading'
import {updateProduct, createProduct} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import FileInput from '../../../../../../_metronic/layout/forms/files/FileInput'
import Input from '../../../../../../_metronic/layout/forms/Inputs/Input'
import {
  ObjectToArrayFormData,
  ObjectToFormData,
} from '../../../../../../_metronic/helpers/function/ObjectToFormData'
import {AppContext} from '../../../../../../AppContext'
import SearshSelect from '../../../../../../_metronic/layout/forms/Inputs/SearshSelect'
import DropZone from '../../../../../../_metronic/layout/forms/files/DropZone'
import {getBlogCategories} from '../../../blog-categories-management/blog-categories-list/core/_requests'
import CustomJoditEditor from '../../../../../../_metronic/layout/forms/Inputs/JoditEditor'
import {getCategories} from '../../../categories-management/categories-list/core/_requests'
import {getCities} from '../../../../../../_metronic/helpers/_request'
import RowMoteur from './RowMoteur'
import Switch from '../../../../../../_metronic/layout/forms/Inputs/Switch'

type Props = {
  isUserLoading: boolean
  user: User
}
const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

const editUserSchema = Yup.object().shape({
  name: Yup.string()
    .required('Le nom est requis')
    .min(3, 'Le nom doit comporter au moins 3 caractères')
    .max(50, 'Le nom ne doit pas dépasser 50 caractères'),
  price: Yup.number()
    .nullable()
    .typeError('Le prix doit être un nombre')
    .min(0, 'Le prix doit être supérieur ou égal à 0'),
  category_id: Yup.string().required('La catégorie est requise').nullable(),
  city_id: Yup.string().required('La ville est requise').nullable(),
  engine_ids: Yup.array()
    .min(1, 'Au moins un moteur doit être sélectionné')
    .required('Le moteur est requis'),
  // images: Yup.array().of(
  //   Yup.mixed()
  //     .required('Le fichier est requis')
  //     .test('fileType', 'Le format du fichier est invalide', (value) => {
  //       if (typeof value === 'string') return true
  //       if (value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)) return true
  //       return false
  //     })
  //     .test(
  //       'fileSize',
  //       'Le fichier doit être inférieur à 2MB',
  //       (value) => typeof value == 'string' || (value && value.size <= 2000000)
  //     )
  // ),
  image: Yup.mixed()
    .required('Le fichier est requis')
    .test(
      'fileType',
      'Le format du fichier est invalide',
      (value) =>
        typeof value === 'string' ||
        (value && ['image/jpeg', 'image/png', 'image/jpg', 'image'].includes(value.type))
    )
    .test(
      'fileSize',
      'Le fichier doit être inférieur à 2MB',
      (value) => typeof value === 'string' || (value && value.size <= 2000000)
    ),
  description: Yup.string()
    .required('La description est requise')
    .min(10, 'La description doit comporter au moins 10 caractères'),
  status: Yup.boolean(),
})

const EditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const {errorToast, successToast} = useContext(AppContext)
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [categories, setCategories] = useState<any[]>()
  const [cities, setCities] = useState<any[]>()
  const [userForEdit] = useState<User>({
    ...user,
    name: user.name || '',
    removeFiles: [],
    price: user.price || '',
    images: user.images ? user.images : '',
    image: user.image ? user.image_url : blankImg,
    engine_ids: user.engines ? user.engines : [],
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const dataToSend = await ObjectToArrayFormData(values)
          await updateProduct(dataToSend, values.id)
          await successToast('Produit mise a jour avec succès')
          await cancel(true)
        } else {
          const dataToSend = await ObjectToArrayFormData(values)

          await createProduct(dataToSend)
          await successToast('Produit crée avec succès')
          await cancel(true)
        }
      } catch (error: any) {
        errorToast(error.response.data.message)
        // if (ex.response && ex.response.data && ex.response.data.error) {
        //   const detailedError = ex.response.data.error
        //   console.error('Detailed Error:', detailedError)
        //   errorToast(detailedError)
        // } else {
        //   console.error('Generic Error:', ex.message)
        //   errorToast('An error occurred. Please try again.')
        // }
        // console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const getCategoriesListe = async () => {
    const {data}: any = await getCategories('?items=all')
    const modifiedData = await data.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))

    setCategories(modifiedData)
  }
  const getCitiesList = async () => {
    const data: any = await getCities()

    const modifiedData = await data.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))
    setCities(modifiedData)
  }
  useEffect(() => {
    getCategoriesListe()
    getCitiesList()
  }, [])
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row my-7 '>
            <div className='col-lg-4 col-md-4 col-sm-12'>
              {' '}
              <FileInput formik={formik} name='image' label='Image' isRequired={true} />
            </div>
            <div className='col-lg-8 col-md-8 col-sm-12'>
              <div className='row mb-4'>
                <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
                  <Input
                    type='string'
                    name='name'
                    formik={formik}
                    label='Nom'
                    isUserLoading={isUserLoading}
                    isRequired={true}
                  />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
                  <Input
                    type='string'
                    name='price'
                    formik={formik}
                    label='Prix'
                    isUserLoading={isUserLoading}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
                  <SearshSelect
                    options={categories && categories.length > 0 ? categories : []}
                    name='category_id'
                    formik={formik}
                    label='Categorie'
                    isUserLoading={isUserLoading}
                    isRequired={true}
                  />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
                  <SearshSelect
                    options={cities && cities.length > 0 ? cities : []}
                    name='city_id'
                    formik={formik}
                    label='Ville'
                    isUserLoading={isUserLoading}
                    isRequired={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-7'>
            <RowMoteur formik={formik} isUserLoading={isUserLoading} />
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12 mb-7'>
            <DropZone
              formik={formik}
              name='images'
              label='Images secondaires'
              isUserLoading={isUserLoading}
            />
          </div>
          <CustomJoditEditor name='description' label='Description' formik={formik} />
          <div className='row my-7'>
            <Switch
              name='status'
              formik={formik}
              label='Statut'
              isUserLoading={isUserLoading}
              isRequired={false}
            />
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Annuler
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Valider</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ListLoading />}
    </>
  )
}

export {EditModalForm}
