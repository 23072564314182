/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {getProducts} from '../../../../app/modules/apps/products-management/products-list/core/_requests'
import {UserInfoCell} from '../../../../app/modules/apps/products-management/products-list/table/columns/UserInfoCell'
import moment from 'moment'

type Props = {
  className: string
}

const TablesWidget5: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<any[]>([])
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [activeTab, setActiveTab] = useState<string>('mois')

  const getData = async () => {
    const products: any = await getProducts('')
    if (products) {
      setData(products.data)
      filterData(products.data, 'mois')
    }
  }

  const filterData = (data: any[], filter: string) => {
    let filtered: any[] = []
    const now = moment()

    switch (filter) {
      case 'mois':
        filtered = data.filter((item) => moment(item.created_at).isSame(now, 'month'))
        break
      case 'semaine':
        filtered = data.filter((item) => moment(item.created_at).isSame(now, 'week'))
        break
      case 'jour':
        filtered = data.filter((item) => moment(item.created_at).isSame(now, 'day'))
        break
      default:
        filtered = data
    }

    setFilteredData(filtered)
  }

  useEffect(() => {
    getData()
  }, [])

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
    filterData(data, tab)
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Derniers Produits</span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                  activeTab === 'mois' ? 'active' : ''
                } fw-bold px-4 me-1`}
                data-bs-toggle='tab'
                onClick={() => handleTabChange('mois')}
              >
                Mois
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                  activeTab === 'semaine' ? 'active' : ''
                } fw-bold px-4 me-1`}
                data-bs-toggle='tab'
                onClick={() => handleTabChange('semaine')}
              >
                Semaine
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                  activeTab === 'jour' ? 'active' : ''
                } fw-bold px-4`}
                data-bs-toggle='tab'
                onClick={() => handleTabChange('jour')}
              >
                Jour
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tab pane */}
          <div className='tab-pane   active'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-200px'>Produit</th>
                    <th className='p-0 min-w-100px'>Catégorie</th>
                    <th className='p-0 min-w-100px'>Prix</th>
                    <th className='p-0 min-w-100px '>Statut</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filteredData &&
                    filteredData.map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          <UserInfoCell user={item} />
                        </td>
                        <td>{item.category.name}</td>
                        <td className='text-muted fw-semibold'>
                          {item.price ? `${item.price} DH` : 'N/A'}
                        </td>
                        <td>
                          {item.status === 'true' ? (
                            <span className='badge badge-light-success'>Disponible</span>
                          ) : (
                            <span className='badge badge-light-danger'>Indisponible</span>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget5}
