import {FC, useContext, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {User} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {ListLoading} from '../components/loading/ListLoading'
import {updateEngine, createEngine} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import Input from '../../../../../../_metronic/layout/forms/Inputs/Input'
import {ObjectToFormData} from '../../../../../../_metronic/helpers/function/ObjectToFormData'
import {AppContext} from '../../../../../../AppContext'
import {useLocation} from 'react-router-dom'
type Props = {
  isUserLoading: boolean
  user: User
}

const editUserSchema = Yup.object().shape({
  name: Yup.string().required('le nom est required'),
})

const EditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const location = useLocation()
  const {errorToast, successToast} = useContext(AppContext)
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit] = useState<User>({
    ...user,
    name: user.name || '',
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values: any, {setSubmitting}) => {
      values.model_id = location?.state
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const dataToSend = await ObjectToFormData(values)
          await updateEngine(dataToSend, values.id)
          await successToast('Moteur mise a jour avec succès')
          await cancel(true)
        } else {
          const dataToSend = await ObjectToFormData(values)

          await createEngine(dataToSend)
          await successToast('Moteur crée avec succès')
          await cancel(true)
        }
      } catch (error: any) {
        errorToast(error.response.data.message)
        // if (ex.response && ex.response.data && ex.response.data.error) {
        //   const detailedError = ex.response.data.error
        //   console.error('Detailed Error:', detailedError)
        //   errorToast(detailedError)
        // } else {
        //   console.error('Generic Error:', ex.message)
        //   errorToast('An error occurred. Please try again.')
        // }
        // console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}

          <div className='row mb-7'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <Input
                type='string'
                name='name'
                formik={formik}
                label='Nom'
                isUserLoading={isUserLoading}
                isRequired={true}
              />
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Annuler
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Valider</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ListLoading />}
    </>
  )
}

export {EditModalForm}
