import React, {useEffect, useState, FC} from 'react'
import {getBrands} from '../../../barnds-management/brands-list/core/_requests'
import {getBrandModels} from '../../../barnd-models-management/brand-models-list/core/_requests'
import {getEngines} from '../../../engines-management/engines-list/core/_requests'
import SearshSelect from '../../../../../../_metronic/layout/forms/Inputs/SearshSelect'
import {KTSVG} from '../../../../../../_metronic/helpers'

type Props = {
  formik: any
  isUserLoading: boolean
}

const RowMoteur: FC<Props> = ({formik, isUserLoading}) => {
  const [brands, setBrands] = useState([])
  const [brandmodels, setBrandModels] = useState([])
  const [engines, setEngines] = useState([])

  const getBrandsList = async () => {
    const {data}: any = await getBrands('items=all')
    const modifiedData = data.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))
    setBrands(modifiedData)
  }

  const getBrandModelList = async () => {
    const {data}: any = await getBrandModels('items=all&brand_id=' + formik.values.brand_id)
    const modifiedData = data.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))
    setBrandModels(modifiedData)
  }

  const getEnginesList = async () => {
    const {data}: any = await getEngines('items=all&model_id=' + formik.values.model_id)
    const modifiedData = data.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))
    setEngines(modifiedData)
  }

  useEffect(() => {
    getBrandsList()
  }, [])

  useEffect(() => {
    getBrandModelList()
  }, [formik.values.brand_id])

  useEffect(() => {
    getEnginesList()
  }, [formik.values.model_id])

  useEffect(() => {
    getBrandsList()
  }, [])

  useEffect(() => {
    if (formik.values.engine_id) {
      const selectedEngine: any = engines.find(
        (engine: any) => engine.value === formik.values.engine_id
      )
      if (selectedEngine) {
        formik.values.engine_ids.push({id: selectedEngine.value, name: selectedEngine.label})
      }
    }
  }, [formik.values.engine_id])

  const removeEngine = (id: any) => {
    formik.setFieldValue(
      'engine_ids',
      formik.values.engine_ids.filter((engine: any) => engine.id !== id)
    )
  }

  return (
    <>
      <div className='d-flex row justify-content-between  mb-4 flex-wrap'>
        <div className='col-sm-12 col-md-4 col-lg-4 mb-4'>
          <SearshSelect
            options={brands && brands.length > 0 ? brands : []}
            name='brand_id'
            formik={formik}
            label='Marques'
            isUserLoading={isUserLoading}
            isRequired={true}
          />
        </div>
        <div className='col-sm-12 col-md-4 col-lg-4 mb-4'>
          <SearshSelect
            options={brandmodels && brandmodels.length > 0 ? brandmodels : []}
            name='model_id'
            formik={formik}
            label='Modèles'
            isUserLoading={isUserLoading}
            isRequired={true}
          />
        </div>
        <div className='col-sm-12 col-md-4 col-lg-4 mb-4'>
          <SearshSelect
            options={engines && engines.length > 0 ? engines : []}
            name='engine_id'
            formik={formik}
            label='Moteurs'
            isUserLoading={isUserLoading}
            isRequired={true}
          />
        </div>
      </div>
      <div>
        <label htmlFor='' className='form-label'>
          Liste des moteurs
        </label>
        <div className='d-flex gap-5 flex-wrap'>
          {formik.values.engine_ids &&
            formik.values.engine_ids.map((item: any) => (
              <div key={item.id} className='alert bg-secondary position-relative'>
                {item.name}
                <span
                  style={{cursor: 'pointer', top: '-5px'}}
                  className='bg-danger position-absolute  right-0 rounded-circle '
                  onClick={() => removeEngine(item.id)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </span>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default RowMoteur
