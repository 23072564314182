/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import FileInput from '../../../../_metronic/layout/forms/files/FileInput'
import SearshSelect from '../../../../_metronic/layout/forms/Inputs/SearshSelect'
import {getCities} from '../../../../_metronic/helpers/_request'
import Input from '../../../../_metronic/layout/forms/Inputs/Input'
import Textarea from '../../../../_metronic/layout/forms/Inputs/Textarea'
import {ListLoading} from '../../apps/users-management/users-list/components/loading/ListLoading'
import {ObjectToFormData} from '../../../../_metronic/helpers/function/ObjectToFormData'
import {createUser} from '../../apps/users-management/users-list/core/_requests'
import {AppContext} from '../../../../AppContext'
import {Link, useNavigate} from 'react-router-dom'

export function Registration() {
  const navigate = useNavigate() // Add this line to use navigate

  const {errorToast, successToast} = useContext(AppContext)

  const [cities, setCities] = useState<any>([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const editUserSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('Le prénom est requis')
      .max(50, 'Le prénom ne doit pas dépasser 50 caractères'),

    last_name: Yup.string()
      .required('Le nom est requis')
      .max(50, 'Le nom ne doit pas dépasser 50 caractères'),

    email: Yup.string().email("L'adresse e-mail est invalide").required("L'email est requis"),

    address: Yup.string()
      .required("L'adresse est requise")
      .max(200, "L'adresse ne doit pas dépasser 200 caractères"),

    phone: Yup.string()
      .matches(/^[0-9]+$/, 'Le téléphone doit contenir uniquement des chiffres')
      .required('Le téléphone est requis')
      .min(10, 'Le téléphone doit contenir au moins 10 chiffres')
      .max(15, 'Le téléphone ne doit pas dépasser 15 chiffres'),

    phone_2: Yup.string()
      .matches(/^[0-9]+$/, 'Le téléphone doit contenir uniquement des chiffres')
      .nullable(true)
      .notRequired()
      .min(10, 'Le téléphone doit contenir au moins 10 chiffres')
      .max(15, 'Le téléphone ne doit pas dépasser 15 chiffres'),

    password: Yup.string()
      .min(6, 'Le mot de passe doit contenir au moins 6 caractères')
      .notRequired(),

    password_confirmation: Yup.string().when('password', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref('password')], 'Les mots de passe doivent correspondre')
        .required('La confirmation du mot de passe est requise'),
    }),

    image: Yup.mixed()
      .required('Le fichier est requis')
      .test(
        'fileType',
        'Le format du fichier est invalide',
        (value) =>
          typeof value === 'string' ||
          (value && ['image/jpeg', 'image/png', 'image/jpg', 'image'].includes(value.type))
      )
      .test(
        'fileSize',
        'Le fichier doit être inférieur à 2MB',
        (value) => typeof value === 'string' || (value && value.size <= 2000000)
      ),
  })
  const getCitiesList = async () => {
    const data: any = await getCities()

    const modifiedData = await data.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))
    setCities(modifiedData)
  }
  useEffect(() => {
    getCitiesList()
  }, [])
  const [userForEdit] = useState<any>({
    first_name: '',
    last_name: '',
    address: '',
    phone: '',
    email: '',
    image: blankImg,
    status: false,
    password: '',
    password_confirmation: '',
  })
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values: any, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const dataToSend = await ObjectToFormData(values)

        await createUser(dataToSend)
        await successToast('Utilisateur crée avec succès')
        navigate('/auth') // Redirect on success
      } catch (error: any) {
        errorToast(error.response.data.message)
      } finally {
        setSubmitting(false)
      }
    },
  })
  return (
    <>
      <form className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div>
          {/* begin::Input group */}
          <div className='row my-7 '>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              {' '}
              <FileInput formik={formik} name='image' label='Image' isRequired={true} />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 '>
              <div className=''>
                <SearshSelect
                  options={cities.length > 0 ? cities : []}
                  name='city_id'
                  formik={formik}
                  label='Ville'
                  isUserLoading={loading}
                  isRequired={true}
                />
              </div>
            </div>
          </div>
          <div className='row mb-7'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <Input
                type='string'
                name='last_name'
                formik={formik}
                label='Nom'
                isUserLoading={loading}
                isRequired={true}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <Input
                type='string'
                name='first_name'
                formik={formik}
                label='Prénom'
                isUserLoading={loading}
                isRequired={true}
              />
            </div>
          </div>
          <div className='row mb-7'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <Input
                type='phone'
                name='phone'
                formik={formik}
                label='Téléphone'
                isUserLoading={loading}
                isRequired={true}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <Input
                type='phone'
                name='phone_2'
                formik={formik}
                label='Téléphone 2'
                isUserLoading={loading}
                isRequired={false}
              />
            </div>
          </div>

          <div className='fv-row mb-7'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <Input
                type='email'
                name='email'
                formik={formik}
                label='Email'
                isUserLoading={loading}
                isRequired={true}
              />
            </div>
          </div>
          <div className='fv-row mb-7'>
            <Textarea name='address' formik={formik} label='Adresse' isUserLoading={loading} />
          </div>
          <div className='row mb-7'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <Input
                type='password'
                name='password'
                formik={formik}
                label='Mot de passe'
                isUserLoading={loading}
                isRequired={true}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <Input
                type='password'
                name='password_confirmation'
                formik={formik}
                label='Confirmer le mot de passe'
                isUserLoading={loading}
                isRequired={true}
              />
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='submit'
            className='btn btn-primary w-100'
            data-kt-users-modal-action='submit'
            disabled={loading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Valider</span>
            {(formik.isSubmitting || loading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth' type='reset' className='btn btn-light mt-3 w-100'>
            Annuler
          </Link>
        </div>

        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || loading) && <ListLoading />}
    </>
  )
}
