import {FC, useContext, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {AppContext} from '../../../../../../../AppContext'
import {Link} from 'react-router-dom'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deletePayment} from '../../core/_requests'
import MyInvoiceComponent from '../../../../../../../_metronic/helpers/components/Invoice'

type Props = {
  id: ID
}

const UserActionsCell: FC<Props> = ({id}) => {
  const {CustomAlert, hasPermission} = useContext(AppContext)
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deletePayment(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
  })

  return (
    <>
      <button
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </button>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <MyInvoiceComponent id={id} />
        {/* begin::Menu item */}
        {hasPermission('update_payment') && (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              Modifier
            </a>
          </div>
        )}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {hasPermission('delete_payment') && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={async () => {
                if (await CustomAlert()) {
                  await deleteItem.mutateAsync()
                }
              }}
            >
              Supprimer
            </a>
          </div>
        )}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {UserActionsCell}
