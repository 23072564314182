// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'

import SimpleInfo from '../../../../../../../_metronic/layout/Colmun/SimpleInfo'
import {Truncate} from '../../../../../../../_metronic/helpers/function/Truncate'
import {truncateHTML} from '../../../../../../../_metronic/helpers/function/TruncateHtml'
import {Switch} from '../../../../../../../_metronic/layout/Colmun/Switch'
import {updateProduct} from '../../core/_requests'
function stringToBoolean(str: string) {
  if (str == 'true') return true
  if (str == 'false') return false
  return false
}
const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Nom' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Categorie' className='min-w-125px' />
    ),
    id: 'category_id',
    Cell: ({...props}) => (
      <SimpleInfo
        value={props.data[props.row.index]?.category && props.data[props.row.index]?.category?.name}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Utilisateur' className='min-w-125px' />
    ),
    id: 'user_id',
    Cell: ({...props}) => (
      <SimpleInfo
        value={
          props.data[props.row.index]?.user &&
          `${props.data[props.row.index]?.user?.first_name} ${
            props.data[props.row.index]?.user?.last_name
          }`
        }
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Ville' className='min-w-125px' />
    ),
    id: 'city_id',
    Cell: ({...props}) => (
      <SimpleInfo
        value={props.data[props.row.index]?.city && props.data[props.row.index]?.city?.name}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Statut' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => (
      <Switch
        checked={stringToBoolean(props.data[props.row.index]?.status)}
        id={props.data[props.row.index].id}
        func={updateProduct}
        query='status'
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
