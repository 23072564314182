// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'

import SimpleInfo from '../../../../../../../_metronic/layout/Colmun/SimpleInfo'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Reference' className='min-w-125px' />
    ),
    id: 'reference',
    Cell: ({...props}) => <SimpleInfo value={props.data[props.row.index]?.reference} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Montante' className='min-w-125px' />
    ),
    id: 'amount',
    Cell: ({...props}) => <SimpleInfo value={props.data[props.row.index]?.amount + ' DH'} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Moyen de paiement' className='min-w-125px' />
    ),
    id: 'payment_method',
    Cell: ({...props}) => <SimpleInfo value={props.data[props.row.index]?.payment_method} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Date de paiement' className='min-w-125px' />
    ),
    id: 'payment_date',
    Cell: ({...props}) => <SimpleInfo value={props.data[props.row.index]?.payment_date} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
