import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {BlogsListWrapper} from './blogs-list/BlogsList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Liste des posts',
    path: '/blogs/liste/all',
    isSeparator: false,
    isActive: true,
  },

  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BlogsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='all'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Tous les posts </PageTitle>
              <BlogsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/blogs/liste/all' />} />
    </Routes>
  )
}

export default BlogsPage
